import * as React from 'react'
import { Link } from 'gatsby'
import Layout from '/src/components/layout'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMobileScreenButton, faGlobe, faUserGear } from '@fortawesome/free-solid-svg-icons'

const SkaffaPage = () => {
	return (
		<Layout pageTitle="Skaffa Brfify">

            <p>Vad roligt att du är intresserad av att skaffa Brfify till din fastighet!<br/>Varmt välkommen att e-posta <a href="mailto:hej@brfify.se">hej@brfify.se</a> så hjälper vi dig.</p>

            { /*
            <div style={{textAlign: 'center', marginTop: '15px'}}>
                <input type="text" name="brfname" placeholder="Vad heter din brf?" className="inputField" style={{textAlign: 'center'}} /><br/>
                <input type="text" name="brforgnr" placeholder="Vilket organisationsnummer?" className="inputField" style={{textAlign: 'center'}} /><br/>
                <input type="number" name="brforgnr" placeholder="Antal lägenheter?" className="inputField" style={{textAlign: 'center'}} /><br/>
                <p style={{fontSize: '12px', margin: '-5px 0px 8px 0px'}}>Se pris <Link to="/pris">här</Link></p>
                <textarea type="text" name="contactinfo" placeholder="Dina kontaktuppgifter?" className="inputField" style={{textAlign: 'center'}}></textarea><br/>
                <br/>
                <button className="submitButton">Skicka</button>
            </div>
            */ }


            { /* <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSf34j5ywLtllwuGJu85UgbMhYgHe3bHcnHCmb6T-UN1dq5_tA/viewform?embedded=true" style={{width: '100%', height: '900px'}} frameborder="0" marginheight="0" marginwidth="0">Läser in...</iframe> */ }
		</Layout>
	)
}

export default SkaffaPage